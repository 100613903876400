import AutoFixIcon from '@mui/icons-material/AutoFixHigh'
import SendIcon from '@mui/icons-material/Send'
import { useCallback, useState } from 'react'
import { useUpdateRegeneratedTextMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { FloatingBarButton } from '@sevenrooms/core/ui-kit/core'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
  VStack,
  DividerLine,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'

interface AssistantModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  subjectLine: string
  previewText: string
  emailBody: string
  onInsertTextsClick: Function
}

interface Fields {
  subjectLine: string
  previewText: string
  emailBody: string
}

export function AssistantModal({ closeHref, subjectLine, previewText, emailBody, onInsertTextsClick }: AssistantModalProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [fields, setFields] = useState<Fields>({
    subjectLine,
    previewText,
    emailBody,
  })
  const [displayedText, setDisplayedText] = useState({ subjectLine, previewText, emailBody })
  const [typingTimers, setTypingTimers] = useState<{ [key in keyof Fields]?: number }>({})
  const [regenerateText] = useUpdateRegeneratedTextMutation()

  const onFieldChange = useCallback(
    (field: keyof Fields) => async (action: string) => {
      setDisplayedText(prev => ({ ...prev, [field]: 'Thinking...' }))

      const response = await regenerateText({
        params: {
          textToRegenerate: fields[field],
          regenerationAction: action,
        },
        venueId,
      })
      if ('data' in response) {
        const text = response.data.regeneratedText ?? ''
        setFields(prev => ({
          ...prev,
          [field]: text,
        }))

        const textArray = text.split('')
        let index = 0

        if (typingTimers[field]) {
          clearInterval(typingTimers[field])
        }

        setDisplayedText(prev => ({ ...prev, [field]: '' }))

        const typingInterval = setInterval(() => {
          if (index < textArray.length) {
            setDisplayedText(prev => ({
              ...prev,
              [field]: prev[field] + textArray[index],
            }))
            index += 1
          } else {
            clearInterval(typingInterval)
          }
        }, 50)

        setTypingTimers(prev => ({ ...prev, [field]: typingInterval }))
      }
    },
    [typingTimers, fields, regenerateText, venueId]
  )

  return (
    <Modal ariaLabel="Base" minWidth="1311px">
      <ModalHeader closeHref={closeHref} width={1000} borderBottomColor="borders">
        <ModalTitle
          title={formatMessage(emailBuilderMessages.assistantModalTitle)}
          subTitle={formatMessage(emailBuilderMessages.assistantModalSubTitle)}
        />
      </ModalHeader>
      <ModalBody height="96vh">
        <VStack alignItems="center" borderTopColor="primaryBackground">
          <VStack p="s" spacing="l" width={700}>
            <VStack>
              <Text fontWeight={700} fontSize="l">
                {formatMessage(emailBuilderMessages.emailSubjectLineTitle)}
              </Text>
              <DividerLine color="dividerLines" margin="none" mt="s" />
            </VStack>
            <Box pl="s" minHeight="18px">
              {displayedText.subjectLine}
            </Box>
            <Box>
              <FloatingBarButton onSelect={onFieldChange('subjectLine')} prefixIcon={<AutoFixIcon />} sendIcon={<SendIcon />} />
            </Box>
            <VStack>
              <Text fontWeight={700} fontSize="l">
                {formatMessage(emailBuilderMessages.emailPreviewText)}
              </Text>
              <DividerLine color="dividerLines" margin="none" mt="s" />
            </VStack>
            <Box pl="s" minHeight="18px">
              {displayedText.previewText}
            </Box>
            <Box>
              <FloatingBarButton onSelect={onFieldChange('previewText')} prefixIcon={<AutoFixIcon />} sendIcon={<SendIcon />} />
            </Box>
            <VStack>
              <Text fontWeight={700} fontSize="l">
                {formatMessage(emailBuilderMessages.emailContent)}
              </Text>
              <DividerLine color="dividerLines" margin="none" mt="s" />
            </VStack>
            <Box pl="s">
              <div dangerouslySetInnerHTML={{ __html: fields.emailBody }} />
            </Box>
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter boxShadow="primaryUp">
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="auto-fix-cancel">
            {formatMessage(emailBuilderMessages.sendTestEmailModalCancelButton)}
          </Button>
          <Button variant="primary" href={closeHref} onClick={() => onInsertTextsClick(fields)} data-test="auto-fix-insert-texts">
            {formatMessage(emailBuilderMessages.assistantModalInsertTextsButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
