import { useMemo, useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { startOfYear } from '@sevenrooms/core/timepiece'
import { Select, type SelectOption } from '@sevenrooms/core/ui-kit/core'
import { MultiSelect, DateRangePicker, DatePickerUtils, Label } from '@sevenrooms/core/ui-kit/form'
import { useScreenWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '../../locales'
import type { CampaignPerformanceFiltersProps, CampaignStatusKeys } from '../../typings'

const LARGE_SIZES: (string | undefined)[] = ['l', 'xl']
export function CampaignPerformanceFilters({ statuses, messages, usePerformanceContext }: CampaignPerformanceFiltersProps) {
  const { formatMessage } = useLocales()
  const [isAllSelected, setIsAllSelected] = useState(true)
  const { breakpoint } = useScreenWidthBreakpoint()
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>()
  const isDesktop = LARGE_SIZES.includes(breakpoint)
  const {
    dateFilter,
    startDate,
    endDate,
    setDateFilter,
    setStartDate,
    setEndDate,
    dateFilters,
    campaigns,
    setCampaignStatus,
    campaignStatus,
    setCampaignIds,
  } = usePerformanceContext()

  const onDateFilterSelect = (value: string | null) => {
    const option = Object.values(dateFilters).find(o => o.id === value) ?? null

    if (!option) {
      return
    }

    const { startDate, endDate } = option.convert()

    if (startDate && endDate) {
      setStartDate(startDate)
      setEndDate(endDate)
    }
    setDateFilter(option)
    if (option.id === 'all') {
      setIsAllSelected(true)
      setStartDate(startOfYear(new Date(2000)))
      setEndDate(new Date())
    } else {
      setIsAllSelected(false)
    }
  }

  const onStartDateChange = (date: Date | null) => {
    if (date !== null) {
      setStartDate(date)
      setDateFilter(dateFilters.custom)
    }
  }

  const campaignSelectOptions: SelectOption[] = useMemo(
    () =>
      campaigns
        ? campaigns
            .filter(campaign => campaign && campaign.status && campaignStatus.includes(campaign.status))
            .map(campaign => ({ id: campaign.id, label: campaign.campaignName }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [campaigns, campaignStatus]
  )

  const onEndDateChange = (date: Date | 'infinite' | null) => {
    if (date !== null) {
      setEndDate(date as Date)
      setDateFilter(dateFilters.custom)
    }
  }

  const onCampaignChange = (options: string[]) => {
    setSelectedCampaigns(options)
    setCampaignIds(options.length > 0 ? options : (campaigns || []).map(({ id }) => id))
  }

  const statusFilters: SelectOption[] = useMemo(
    () =>
      statuses.map((status: CampaignStatusKeys) => ({
        id: status,
        label: formatMessage(messages[status.toLowerCase() as Lowercase<CampaignStatusKeys>]),
      })),
    [formatMessage, statuses, messages]
  )

  const statusIds = useMemo(() => statusFilters.map(({ id }) => id), [statusFilters])

  const renderDateFilterContent = () => (
    <>
      <Label primary={formatMessage(campaignCenterMessages.dateFilterText)} width={145}>
        <Select
          options={Object.values(dateFilters)}
          searchable={false}
          onChange={onDateFilterSelect}
          value={dateFilter?.id || dateFilters.all.id}
          scrollLock
        />
      </Label>
      {!isAllSelected && (
        <Label primary={formatMessage(campaignCenterMessages.dateRangeText)}>
          <DateRangePicker
            id="campaign-performance-filters"
            startDate={startDate}
            endDate={endDate}
            startDateProps={{
              isOutsideRange: endDate === 'infinite' ? undefined : DatePickerUtils.isAfterFn(endDate),
            }}
            endDateProps={{
              isOutsideRange: date => DatePickerUtils.isAfterCurrentFn()(date) || DatePickerUtils.isBeforeFn(startDate)(date),
            }}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            showClearDate={false}
          />
        </Label>
      )}
    </>
  )

  return (
    <Box>
      <VStack spacing="m">
        <HStack spacing="s">
          <Label primary={formatMessage(campaignCenterMessages.campaignTableStatusHeader)}>
            <Box width="220px">
              <MultiSelect
                options={statusFilters}
                useCheckboxItems
                usePills={false}
                displayAsTextWhenCollapsed
                onChange={options => {
                  const statuses = options.length > 0 ? options : statusIds
                  setCampaignStatus(statuses)
                  setSelectedCampaigns([])
                }}
                scrollLock
                showMoreLabel=""
                placeholder={formatMessage(campaignCenterMessages.campaignTableStatusPlaceholder)}
              />
            </Box>
          </Label>
          <Label primary={formatMessage(messages.campaignText)}>
            <Box width="220px">
              <MultiSelect
                options={campaignSelectOptions}
                value={selectedCampaigns}
                onChange={onCampaignChange}
                scrollLock
                displayAsTextWhenCollapsed
                useCheckboxItems
                usePills={false}
                showRemoveAllButton
                showMoreLabel=""
                searchable
                placeholder={formatMessage(campaignCenterMessages.campaignTableCampaignsPlaceholder)}
                wrapText
                isControlled
              />
            </Box>
          </Label>
          {(isDesktop || isAllSelected) && renderDateFilterContent()}
        </HStack>
        {!isDesktop && !isAllSelected && <HStack spacing="s">{renderDateFilterContent()}</HStack>}
      </VStack>
    </Box>
  )
}
