import _ from 'lodash'
import {
  FORMAT_URL_START,
  FORMAT_URL_SUCCESS,
  FORMAT_URL_FAIL,
  DONE_LOADING_IFRAME,
  SAVE_ACTIVITY_LOG_START,
} from 'mgr/pages/shared/actions/reporting/ActionTypes'

const initialState = {
  formattedUrl: '',
  isLoading: false,
  iFrameLoading: true,
  reportViewHistory: [],
}

const lookerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORMAT_URL_START:
      return {
        ...state,
        isLoading: true,
        iFrameLoading: true,
      }
    case FORMAT_URL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formattedUrl: action.data,
      }
    case DONE_LOADING_IFRAME:
      return {
        ...state,
        iFrameLoading: false,
      }
    case FORMAT_URL_FAIL:
      return {
        ...state,
        isLoading: false,
        iFrameLoading: false,
      }
    case SAVE_ACTIVITY_LOG_START:
      return {
        ...state,
        reportViewHistory: [action.reportPath, ...state.reportViewHistory],
      }
    default:
      return state
  }
}

export default lookerReducer
