import * as React from 'react'
import { SecondaryHeader, PRIMARY_HEADER_HEIGHT, SettingsPageContentLayout } from '@sevenrooms/core/ui-kit/layout'
import { PrimaryHeader } from '../../PrimaryHeader'

export interface SettingsPageContent {
  title?: React.ReactNode
  breadcrumbs?: React.ReactNode
  description?: React.ReactNode
  image?: React.ReactNode
  actions?: React.ReactNode
  tab?: React.ReactNode
  headerWidth?: string
  secondHeaderMaxWidth?: React.CSSProperties['maxWidth']
  secondHeaderTextMaxWidth?: React.CSSProperties['maxWidth']
  isShowingSideNavigation?: boolean
  headerBackground?: string
}

export function SettingsPageContent({
  title,
  breadcrumbs,
  description,
  image,
  actions,
  headerWidth,
  secondHeaderMaxWidth = '968px',
  secondHeaderTextMaxWidth,
  isShowingSideNavigation,
  headerBackground,
  tab,
  children,
}: React.PropsWithChildren<SettingsPageContent>) {
  const [headerHeight, setHeaderHeight] = React.useState<number>(0)

  return (
    <>
      <PrimaryHeader width={headerWidth} isShowingSideNavigation={isShowingSideNavigation} />

      {title && (
        <SecondaryHeader
          top={PRIMARY_HEADER_HEIGHT}
          title={title}
          breadcrumbs={breadcrumbs}
          description={description}
          image={image}
          onHeightChange={setHeaderHeight}
          actions={actions}
          width={headerWidth}
          maxWidth={secondHeaderMaxWidth}
          textMaxWidth={secondHeaderTextMaxWidth}
          backgroundImage={headerBackground}
          tab={tab}
        />
      )}

      <SettingsPageContentLayout paddingTop={(title ? headerHeight : 0) + PRIMARY_HEADER_HEIGHT}>{children}</SettingsPageContentLayout>
    </>
  )
}
