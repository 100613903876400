import { srGet, srPost, convertToFormData } from '@sevenrooms/core/api'

const fetchAvailabilityTimes = (
  actualId,
  venueId,
  date,
  partySize,
  shiftPersistentId,
  duration,
  seatingAreaId,
  startTimeSlot,
  endTimeSlot,
  errHandler,
  bufferMins
) => {
  const dateParam = moment(date).format('MM-DD-YYYY')

  return srGet(
    `/manager/${venueId}/availability/${dateParam}`,
    {
      shift_persistent_id: shiftPersistentId,
      actual_id: actualId,
      venue_seating_area_id: seatingAreaId,
      party_size: partySize,
      duration,
      start_time_sort_order: startTimeSlot,
      end_time_sort_order: endTimeSlot,
      buffer: window.globalInit.venueSettings.is_buffers_enabled ? bufferMins : 0,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    if (response.availability) {
      // eslint-disable-next-line array-callback-return
      response.availability.map(timeslot => {
        // eslint-disable-next-line no-param-reassign
        timeslot.timeMoment = moment(timeslot.time_iso)
      })
    }
    return response
  })
}

const getReservationHold = ({
  venueId,
  venueKey,
  date,
  time,
  shiftPersistentId,
  accessPersistentId,
  partySize,
  duration,
  actualId,
  channel,
}) => {
  const postData = {
    date: date || '',
    time: time || '',
    shift_persistent_id: shiftPersistentId || '',
    party_size: partySize || '',
    actual_id: actualId || undefined,
    picked_duration: duration || undefined,
  }

  if (accessPersistentId) {
    const formattedPostData = convertToFormData({
      ...postData,
      channel: channel || 'SEVENROOMS_WIDGET',
      access_persistent_id: accessPersistentId || '',
      include_duration_ars_when_duration_picker: true,
    })
    return fetch(`/booking/dining/widget/${venueKey}/hold-reservation`, {
      body: formattedPostData,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(response => ({ data: response }))
      .catch(e => {
        throw e
      })
  }
  return srPost(`/api-yoa/manager/${venueId}/hold-shift-reservation`, {}, { json: true, skipInterfaceError: true }, {}, postData)
    .then(response => response)
    .catch(e => {
      throw e
    })
}

const releaseReservationHold = ({ venueKey, previousHoldShiftPersistentId, reservationHoldId, previousHoldResDate }) => {
  const postData = {
    shift_persistent_id: previousHoldShiftPersistentId || '',
    reservation_hold_id: reservationHoldId || '',
    date: previousHoldResDate || '',
  }

  const formattedPostData = convertToFormData(postData)

  return fetch(`/booking/dining/widget/${venueKey}/release-reservation-hold`, {
    body: formattedPostData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => {
      if (response.status !== 200) {
        throw response.status
      }
      return response.json()
    })
    .catch(e => {
      throw e
    })
}

export default {
  fetchAvailabilityTimes,
  getReservationHold,
  releaseReservationHold,
}
